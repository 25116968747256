.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

button {
  padding: 5px 14px;
  font-size: 0.9em;
  background-color: #e6cc5c;
  border: none;
  border-radius: 8px;
}

.erase-button {
  margin: 20px;
  padding: 14px;
}

.submit-button {
  height: stretch;
  height: 100%;
  border-radius: 0 8px 8px 0;
}

.message {
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
  padding: 20px;
  background-color: #3a3f46;
}

img {
  -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s; /* Firefox < 16 */
  -ms-animation: fadein 0.5s; /* Internet Explorer */
  -o-animation: fadein 0.5s; /* Opera < 12.1 */
  animation: fadein 0.5s;
}

h1,
p {
  -webkit-animation: fadein 0.8s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.8s; /* Firefox < 16 */
  -ms-animation: fadein 0.8s; /* Internet Explorer */
  -o-animation: fadein 0.8s; /* Opera < 12.1 */
  animation: fadein 0.8s;
}

input,
button {
  -webkit-animation: fadein 1.3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1.3s; /* Firefox < 16 */
  -ms-animation: fadein 1.3s; /* Internet Explorer */
  -o-animation: fadein 1.3s; /* Opera < 12.1 */
  animation: fadein 1.3s;
}

input {
  border-radius: 8px 0 0 8px;
  padding: 5px;
  width: 80%;
  font-size: 1em;
  border: none;
  height: stretch;
}

.add-form {
  border-radius: 8px;
  width: 100%;
  padding: 20px;
  margin: 5px;
}

.form-container {
  display: flex;
  align-items: center;
  margin: 20px 0;
  height: 44px;
}

.add-form-container {
  width: 80%;
  flex-direction: column;
}

.add-form label {
  text-align: left;
  align-self: baseline;
  margin: 15px;
}

.error {
  font-size: 0.8em;
  color: #d94948;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
